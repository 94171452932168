import { useTranslation } from 'react-i18next';
import './savings-indicator.module.scss';

/* eslint-disable-next-line */
export interface SavingsIndicatorProps {
  savings: number | undefined;
}

export function SavingsIndicator({ savings }: SavingsIndicatorProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="col-12 col-lg-6 text-center">
      <h3>{t('savings')}</h3>
      <h1 className="bigger">
        {savings !== undefined ? Math.round(savings * 100) : '...'}
      </h1>
    </div>
  );
}

export default SavingsIndicator;
