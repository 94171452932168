import {
  CalculateSavingsRequestBody,
  CalculateSavingsResponseBody,
  RequestInformationRequestBody,
  RequestInformationResponseBody,
} from './api-types';

export async function getSavingsPerWeek(input: CalculateSavingsRequestBody) {
  const response = await fetch(`/api/v1/calculate_savings`, {
    method: 'post',
    body: JSON.stringify(input),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return (await response.json()) as CalculateSavingsResponseBody;
}

export async function requestInformation(input: RequestInformationRequestBody) {
  const response = await fetch(`/api/v1/request_information`, {
    method: 'post',
    body: JSON.stringify(input),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return (await response.json()) as RequestInformationResponseBody;
}
