import styles from './app.module.scss';
import { ToastMessage, Toasts } from '@nlg-demo-and-roi-calculator/shared';
import RoiOptions from './roi-options/roi-options';
import { nlgDemo } from './urls-config';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getSavingsPerWeek, requestInformation } from './api';
import { CalculationInput, RequestFormData } from './api-types';
import OptionsBlockButtons from './options-block-buttons/options-block-buttons';
import ReportRequestForm from './report-request-form/report-request-form';
import SavingsIndicator from './savings-indicator/savings-indicator';
import { useParams } from 'react-router-dom';

export interface UrlParams {
  localeId: string;
}

export function App() {
  const { t, i18n } = useTranslation();
  const { localeId } = useParams<UrlParams>();

  const [inputValues, setInputValues] = useState<CalculationInput>({
    automation_degree: 0.3,
    branch: 'Mode',
    number_of_employees: 10,
    number_of_texts: 10,
  });

  const [requestFormValues, setRequestFormValues] = useState<RequestFormData>({
    email: '',
    name: '',
    phone_number: '',
    company: '',
  });

  const [savings, setSavings] = useState<number>();

  const [toastMessages, setToastMessages] = useState<ToastMessage[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function refreshSavings() {
    setIsLoading(true);
    const { savings_time_percent } = await getSavingsPerWeek(inputValues);
    setSavings(savings_time_percent);
    setIsLoading(false);
  }

  function changeRequestFormFieldValue(
    id: keyof RequestFormData,
    value: RequestFormData[typeof id]
  ) {
    setRequestFormValues({ ...requestFormValues, [id]: value });
  }

  function changeOptionValue(
    id: keyof CalculationInput,
    value: CalculationInput[typeof id]
  ) {
    setInputValues({ ...inputValues, [id]: value });
  }

  async function submitInformationRequest() {
    const { success } = await requestInformation({
      calculation_input: inputValues,
      ...requestFormValues,
    });

    if (success) {
      setToastMessages([
        ...toastMessages,
        {
          message: t('informationRequestResultMessages.success'),
        },
      ]);
    } else {
      setToastMessages([
        ...toastMessages,
        {
          message: t('informationRequestResultMessages.failure'),
        },
      ]);
    }
  }

  function hideMessage(i: number) {
    const newMessages = [...toastMessages];
    newMessages.splice(i, 1);
    setToastMessages(newMessages);
  }

  useEffect(() => {
    refreshSavings();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(localeId);
  }, []);

  return (
    <>
      <div className="returnofinvest-visible">
        <article className="container-fluid">
          <div className="row align-items-center">
            <section className="col-12 col-lg-8 max-80vh">
              <div className="row returnofinvest">
                <div className="row">
                  <SavingsIndicator savings={savings}></SavingsIndicator>
                  <ReportRequestForm
                    onChangeFieldValue={changeRequestFormFieldValue}
                    onSubmit={submitInformationRequest}
                  ></ReportRequestForm>
                </div>
              </div>{' '}
            </section>
            <section
              className="col-12 col-lg-4 bg-white shadow p-3 p-md-5 mt-5 mt-lg-0 rounded"
              id="options"
            >
              <div className="row">
                <OptionsBlockButtons
                  textGeneratorUrl={nlgDemo}
                  onRefreshSavingsClick={refreshSavings}
                  isLoading={isLoading}
                ></OptionsBlockButtons>
                <RoiOptions
                  values={inputValues}
                  onValueChange={changeOptionValue}
                ></RoiOptions>
              </div>
            </section>
          </div>
        </article>
      </div>
      <Toasts messages={toastMessages} hideMessage={hideMessage}></Toasts>
    </>
  );
}

export default App;
