import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CalculationInput } from '../api-types';
import './roi-options.module.scss';

import NoUiSlider from 'nouislider-react';
import wNumb from 'wnumb';

/* eslint-disable-next-line */
export interface RoiOptionsProps {
  values: CalculationInput;
  onValueChange: (
    id: keyof CalculationInput,
    newValue: CalculationInput[typeof id]
  ) => void;
}

const number_of_texts_options = [
  { value: 10, label: '10' },
  { value: 100, label: '100' },
  { value: 1000, label: '1.000' },
  { value: 10000, label: '10.000 +' },
];

const number_of_employees_options = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1.000 +' },
];

/* TODO: I18n? */
const branches_options = ['Finanzen', 'Mode', 'Immobilien', 'Baumarkt'];

export function RoiOptions({ values, onValueChange }: RoiOptionsProps) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="col-12 d-grid mb-3">
        <p className="form-label">{t('optionLabels.number_of_texts')}</p>
        <ToggleButtonGroup
          type="radio"
          name="number_of_texts"
          value={values['number_of_texts']}
          onChange={(e) => onValueChange('number_of_texts', e)}
        >
          {number_of_texts_options.map((o) => (
            <ToggleButton
              key={o.value}
              value={o.value}
              variant="outline-secondary"
              id={`number_of_texts_${o.value}`}
            >
              {o.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <div className="col-12 d-grid mb-3">
        <p className="form-label">{t('optionLabels.number_of_employees')}</p>

        <ToggleButtonGroup
          type="radio"
          name="number_of_employees"
          value={values['number_of_employees']}
          onChange={(e) => onValueChange('number_of_employees', e)}
        >
          {number_of_employees_options.map((o) => (
            <ToggleButton
              key={o.value}
              value={o.value}
              variant="outline-secondary"
              id={`number_of_employees_${o.value}`}
            >
              {o.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <div className="col-12 d-grid mb-3">
        <label htmlFor="automatisierung" className="form-label">
          {t('optionLabels.automation_degree')}
        </label>
        <NoUiSlider
          range={{ min: 0, max: 100 }}
          start={values['automation_degree'] * 100}
          id="automatisierung"
          connect={'lower' as any}
          tooltips={[wNumb({ decimals: 0, suffix: '%' })]}
          step={1}
          onChange={(e) => onValueChange('automation_degree', e[0] / 100)}
        ></NoUiSlider>
      </div>
      <div className="col-6 mb-3">
        <p className="form-label">{t('optionLabels.branch')}</p>
        <Form.Select
          className="w-100"
          aria-label=""
          value={values['branch']}
          onChange={(e) =>
            onValueChange('branch', (e.target as HTMLSelectElement).value)
          }
        >
          {branches_options.map((b) => (
            <option value={b} key={b}>
              {b}
            </option>
          ))}
        </Form.Select>
      </div>
    </>
  );
}

export default RoiOptions;
