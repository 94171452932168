import { useTranslation } from 'react-i18next';
import './options-block-buttons.module.scss';
import { media } from '@nlg-demo-and-roi-calculator/shared';

/* eslint-disable-next-line */
export interface OptionsBlockButtonsProps {
  textGeneratorUrl: string;
  isLoading: boolean;
  onRefreshSavingsClick: () => void;
}

export function OptionsBlockButtons({
  textGeneratorUrl,
  onRefreshSavingsClick,
  isLoading,
}: OptionsBlockButtonsProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="col-12 d-grid mb-3">
      <a
        type="button"
        className="btn btn-outline-secondary w-100 mb-2"
        href={textGeneratorUrl}
        target="_top"
      >
        <media._switch.ReactComponent className="me-1 mb-1"></media._switch.ReactComponent>{' '}
        {t('gotoTextGenerator')}
      </a>
      <button
        type="button"
        className="btn btn-primary w-100"
        onClick={() => onRefreshSavingsClick()}
      >
        <media.update.ReactComponent
          className={'rotation ' + (isLoading ? 'isrotating' : '')}
        ></media.update.ReactComponent>{' '}
        {t('refreshRoi')}
      </button>
    </div>
  );
}

export default OptionsBlockButtons;
