// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "img.report-request-form_nlg-preview__2p7Zx {\n  width: 119px;\n  height: 158px;\n  border-radius: 3px;\n}", ""]);
// Exports
exports.locals = {
	"nlg-preview": "report-request-form_nlg-preview__2p7Zx"
};
module.exports = exports;
