import { useTranslation } from 'react-i18next';
import { RequestFormData } from '../api-types';
import './report-request-form.module.scss';
import styles from './report-request-form.module.scss';

/* eslint-disable-next-line */
export interface ReportRequestFormProps {
  onSubmit: () => void;
  onChangeFieldValue: (
    id: keyof RequestFormData,
    value: RequestFormData[typeof id]
  ) => void;
}

export function ReportRequestForm({
  onChangeFieldValue,
  onSubmit,
}: ReportRequestFormProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="col-12 col-lg-6">
      <form
        onSubmit={(e) => {
          onSubmit();
          e.preventDefault();
        }}
      >
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder={t('requestForm.fieldPlaceholders.name')}
            onChange={(e) => onChangeFieldValue('name', e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder={t('requestForm.fieldPlaceholders.email')}
            onChange={(e) => onChangeFieldValue('email', e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="company"
            placeholder={t('requestForm.fieldPlaceholders.company')}
            onChange={(e) => onChangeFieldValue('company', e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="tel"
            className="form-control"
            id="phone_number"
            placeholder={t('requestForm.fieldPlaceholders.phone_number')}
            onChange={(e) => onChangeFieldValue('phone_number', e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-success w-100 mb-3">
          {t('requestForm.viewReport')}
        </button>
      </form>
      <div className="row">
        <div className="col-4">
          <a href={t('requestForm.downloadLink')} target="_top">
            <img
              src="assets/media/nlg-preview.webp"
              alt="PDF file"
              className={styles['nlg-preview']}
            />
          </a>
        </div>
        <div
          className="col-8"
          dangerouslySetInnerHTML={{
            __html: t('requestForm.analysisDescription'),
          }}
        ></div>
      </div>
    </div>
  );
}

export default ReportRequestForm;
